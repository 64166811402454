import React from "react";
import BackgroundVideo from "../components/BackgroundVideo/BackgroundVideo";
import LoginForm from "../components/LoginForm/LoginForm";

export default function LoginPage() {
    return (
        <>
            <BackgroundVideo></BackgroundVideo>
            <LoginForm></LoginForm>
        </>
    );
}
